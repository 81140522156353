import React from "react";
import { Link } from "gatsby";

const Menu = () => {
  return (
    <ul>
      <li key="/">
        <Link to="/" id="home" className="page-link">ホーム</Link>
      </li>
      <li key="/tags/">
        <Link to="/tags/" className="page-link">タグ</Link>
      </li>
      <li key="/about/">
        <Link to="/about/" className="page-link">サイト</Link>
      </li>
    </ul>
  );
};

export default Menu;