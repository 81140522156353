import * as React from "react"
import Header from "../components/header";
import Footer from "../components/footer";

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <Header title={title}/>
      <main className="global-main">{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
