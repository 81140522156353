import * as React from "react"
import { Link } from "gatsby"
import Menu from "../components/menu";

const Header = ({title}) => {

  let header = (
    <h1 className="main-heading">
      <Link to="/">{title}</Link>
    </h1>
  )

  return (
    <header className="global-header">
      <nav className="navbar">
      {header}
      <Menu />
      </nav>
    </header>

  )
}

export default Header
